/*-----------------------------------------------
|   Object fit and Z-index
-----------------------------------------------*/
.fit-cover{ object-fit: cover; }
.z-index-1{ z-index: 1; }
.z-index--1{ z-index: -1; }

/*-----------------------------------------------
|   Zanimation
-----------------------------------------------*/
*[data-zanim-trigger]{ opacity: 0; }


/*-----------------------------------------------
|   Sidebar
-----------------------------------------------*/
.sticky-sidebar{ top: 85px; }

/*-----------------------------------------------
|   Custom circle
-----------------------------------------------*/
.circle-dashed{
  border: 1px dashed $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

/*-----------------------------------------------
|   Miscellaneous
-----------------------------------------------*/
@include hover-focus{
  .hover-text-decoration-none{
    text-decoration: none;
  }
}
.resize-none{ resize: none; }

/*-----------------------------------------------
|   breadcrumb-item
-----------------------------------------------*/
.bc-caret-right {
  .breadcrumb-item + .breadcrumb-item::before {
    content: '';
    border: 0.25rem solid transparent;
  }
  &.bc-light .breadcrumb-item + .breadcrumb-item::before{ border-left-color: $light; }
  &.bc-dark .breadcrumb-item + .breadcrumb-item::before{ border-left-color: $dark; }
}
.navbar-border-bottom {
  border-bottom:1px #ffffff42 solid
}

.down-arrow {
  position: absolute;
  bottom:0;
  left: 50%;
  transform: translate(-50%,20%);
  display: inline-block;
}
.h-banner {
  min-height: 20.5rem;
  @include media-breakpoint-up(lg){ min-height: 26rem; }
  @include media-breakpoint-up(xxl){ min-height: 34rem; }
}

.is_stuck + div { pointer-events: none; }

.shadow-white {
  box-shadow: $box-shadow-white;
}
.h-team {
  min-height: 15rem;
}
