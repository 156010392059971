/*-----------------------------------------------
|   Drop cap
-----------------------------------------------*/
.dropcap::first-letter {
  font-size: 3em;
  float: left;
  line-height: .92;
  margin-right: 0.375rem;
}


/*-----------------------------------------------
|   Letter Spacing
-----------------------------------------------*/
.ls { letter-spacing: 0.04em; }
.ls-2{ letter-spacing: 0.25em; }



/*-----------------------------------------------
|   List
-----------------------------------------------*/
.bullet-inside { list-style-position: inside; }

// Style Check mark
.style-check li {
  position: relative;
  list-style-type: none;
  padding-left: .25rem;
  &:before {
    content: url(data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2hlY2sgZmEtdy0xNiIgZGF0YS1mYS10cmFuc2Zvcm09InNocmluay0zIiBhcmlhLWhpZGRlbj0idHJ1ZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGVjayIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBkYXRhLWZhLWkyc3ZnPSIiIHN0eWxlPSJ0cmFuc2Zvcm0tb3JpZ2luOiAwLjVlbSAwLjVlbTsiPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI1NiAyNTYpIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLCAwKSAgc2NhbGUoMC44MTI1LCAwLjgxMjUpICByb3RhdGUoMCAwIDApIj48cGF0aCBmaWxsPSIjMzdEODk0IiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjU2IC0yNTYpIj48L3BhdGg+PC9nPjwvZz48L3N2Zz4=);
    padding-right: 0.1875rem;
    position: absolute;
    top: 0.0625rem;
    left: -1rem;
    background-repeat: no-repeat;
    width: 1.175rem;
  }
}

/*-----------------------------------------------
|   Blockquote
-----------------------------------------------*/
.blockquote-content{
  font-style: italic;
  position: relative;
  &:before{
    position: absolute;
    left: -3rem;
    top: -0.5rem;
    line-height: 1;
    content: quote('\201C');
    display: inline-block;
    color: $gray-300;
    font-size: map_get($font-sizes, 6);
  }
}

.blockquote-footer{
  &::before {
    transform: translateY(0.125rem);
    font-weight: $font-weight-normal;
    display: inline-block;
  }
}
