/*-----------------------------------------------
|   Hover Box
-----------------------------------------------*/
.hoverbox {
  position: relative;
  overflow: hidden;
  .hoverbox-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    margin: 0 !important;
    display: flex;
  }
  .hoverbox-primary-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
  }
  @include hover-focus {
    .hover-box-content-initial { opacity: 0; }
    .hoverbox-content { opacity: 1; }
    .as-hoverbox-content { z-index: 1; }
  }
}

.hoverbox-content-gradient { background: linear-gradient(transparent, $black); }

.hover-cardgroup{
  transition: $transition-base;
  border-radius: 12px;
  @include hover-focus {
    background-color: $white !important;
    box-shadow:  0 5px 24px rgba(255, 255, 255, 0.32);
    .title {color:$extra-1 !important;}
    .subtitle { color:$gray-900;}
  }
}

$custom-colors: (
        'primary': $primary,
        'secondary': $secondary,
        'success': $success,
        'info': $info,
        'warning': $warning,
        'danger': $danger,
        'default': $dark,
);
@each $color, $value in $custom-colors {
  .social-icon-hover-#{$color} {
    .social-icon {
      transition: $transition-base;
      color: $gray-700
    }

    @include hover-focus {
      .social-icon {
        color: $value !important;
      }
    }
  }
}


@include media-breakpoint-up(xl) {
  .dropdown-menu-on-hover{
    &.dropdown {
      @include hover-focus {
        .dropdown-menu { display: block; }
      }
    }
  }
}


.dropdown-menu {
    .nav-item , .dropdown-item{
      @include hover-focus{background-color: $gray-200; color:$gray-900}
    }
}
